export const locale = {
    lang: "en",
    data: {
        NAV: {
            APPLICATIONS: "Applications",
            SAMPLE: {
                TITLE: "Sample",
                BADGE: "25",
            },
            DASHBOARD: "Dashboard",
            VISIT: "Visit",
            SURVEY: "Survey",
        },
    },
};
