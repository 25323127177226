import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "dashboard",
        title: "Dashboard",
        translate: "NAV.DASHBOARD",
        type: "group",
        children: [
            {
                id: "survey",
                title: "Surveys",
                translate: "NAV.SURVEY",
                type: "item",
                icon: "list_alt",
                url: "/survey",
                //badge    : {
                //    title    : '25',
                //    translate: 'NAV.SAMPLE.BADGE',
                //    bg       : '#F44336',
                //    fg       : '#FFFFFF'
                //}
            },
        ],
    },
];
