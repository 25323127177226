import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from "@angular/router";
import { AuthService } from "app/main/shared/services/auth/auth.service";
import { Observable } from "rxjs";
import { tap, take, map } from "rxjs/operators";
import { UserService } from "../../services/user/user.service";

@Injectable({
    providedIn: "root",
})
export class AllowedUserGuard implements CanActivate {
    constructor(
        public authService: AuthService,
        public router: Router,
        public userService: UserService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise(async (res, rej) => {
            const allowedUsers =
                await this.userService.getAllowedAdminUsersUID();
            const user = await this.authService.getAuthState().toPromise();

            if (!allowedUsers.includes(user.uid)) {
                this.authService.signOut();
                alert("User is not allowed.");
                return res(false);
            } else {
                return res(true);
            }
        });
    }
}
