import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from "@angular/router";
import { AuthService } from "app/main/shared/services/auth/auth.service";
import { Observable } from "rxjs";
import { tap, take, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    constructor(public authService: AuthService, public router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        /** Check if user is authenticated, if is not authenticated,
         * then navigate to 'auth/login' */

        return this.authService.getAuthState().pipe(
            map((user) => {
                if (user) {
                    return true;
                } else {
                    this.router.navigate(["auth/login"]);
                    return false;
                }
            })
        );
    }
}
