import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";

//Environments Variables
import { environment } from "environments/environment";

//Firebase Modules
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";

import { AuthGuard } from "./main/shared/guards/user/auth.guard";
import { EmailVerificationGuard } from "./main/shared/guards/user/email-verification.guard";

import { AllowedUserGuard } from "./main/shared/guards/user/allowed-user.guard";
import { AngularFirestoreModule } from "@angular/fire/firestore";

const appRoutes: Routes = [
    {
        path: "auth",
        loadChildren: () =>
            import("./main/authorization/authorization.module").then(
                (m) => m.UserModule
            ),
    },
    {
        path: "visit",
        loadChildren: () =>
            import("./main/visit/visit.module").then((m) => m.VisitModule),
        canActivate: [AuthGuard, AllowedUserGuard],
    },
    {
        path: "survey",
        loadChildren: () =>
            import("./main/survey-response/survey-response.module").then(
                (m) => m.SurveyResponseModule
            ),
        canActivate: [AuthGuard, AllowedUserGuard],
    },

    {
        path: "not-found",
        loadChildren: () =>
            import("./main/not-found/not-found.module").then(
                (m) => m.NotFoundModule
            ),
    },
    {
        path: "",
        redirectTo: "auth/login",
        pathMatch: "full",
    },
    {
        path: "**",
        redirectTo: "/not-found",
    },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,

        //Firebase Module
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
