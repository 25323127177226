// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    firebaseConfig: {
        apiKey: "AIzaSyCLx_c7z_9cnIwTA8gGwg9dXJA-J4Ceko4",
        authDomain: "wisy-rex.firebaseapp.com",
        databaseURL: "https://wisy-rex.firebaseio.com",
        projectId: "wisy-rex",
        storageBucket: "wisy-rex.appspot.com",
        messagingSenderId: "219334465902",
        appId: "1:219334465902:web:5a494b3f285c6d3109897f",
        measurementId: "G-7H4L0YGCPM",
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
