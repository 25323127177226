import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { User, UserPrivate } from "app/main/shared/services/user/user.model";
import * as firebase from "firebase";

import { first, map, take, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    fbUser: firebase.default.User;
    public user = {} as User;
    public userPrivate = {} as UserPrivate;

    constructor(
        public _fbAuth: AngularFireAuth,
        public afs: AngularFirestore,
        public router: Router
    ) {
        /* Saving user data in localstorage when
        logged in and setting up null when logged out */
        this._fbAuth.authState.subscribe(async (user) => {
            //console.log("Auth Session onAuthStateChanged: ", user);

            if (user) {
                this.fbUser = user;
                this._loadUser(this.fbUser);

                /** Grab data from firestore */
                const firestoreUserData = await this.getUserData();

                /**@TODO: Remove local storage management */
                localStorage.setItem("user", JSON.stringify(this.fbUser));
                JSON.parse(localStorage.getItem("user"));

                /** Method to set email_verification to true, should only trigger one time */

                if (
                    this.fbUser.emailVerified &&
                    !firestoreUserData.emailVerified
                ) {
                    /**
                     * - Set Email Authentication = True
                     * - Triggers a cloud firestore trigger onEmailVerified
                     */
                    await this._setEmailVerfication();
                }
            } else {
                localStorage.setItem("user", null);
                JSON.parse(localStorage.getItem("user"));
            }
        });
    }

    /**
     * =====================================================================
     * Authentication Handler
     * =====================================================================
     */

    getAuthState() {
        return this._fbAuth.authState.pipe(first());
    }

    async signUpWithEmailAndPassword(
        email: string,
        password: string
    ): Promise<firebase.default.User> {
        try {
            const result = await this._fbAuth.createUserWithEmailAndPassword(
                email,
                password
            );

            this.fbUser = result.user;
            // this._createUser(this.fbUser);
            return this.fbUser;
            // this.setUserData(this.user);
        } catch (err) {
            throw err;
        }
    }

    /**Send verification email to user and proceed to navigate to "Mail Confirm Page" */
    async sendVerificationEmail(): Promise<void> {
        await this.fbUser.sendEmailVerification();
        this.router.navigate(["auth/mail-confirm"]);
        return;
    }

    /** Log in to user email and password */
    async signInWithUserAndPassword(
        email: string,
        password: string
    ): Promise<void> {
        const user = await this._fbAuth.signInWithEmailAndPassword(
            email,
            password
        );
    }

    /**
     * Send an email to reset password to a new one.
     */
    async sendPasswordResetEmail(passwordResetEmail): Promise<void> {
        try {
            await this._fbAuth.sendPasswordResetEmail(passwordResetEmail);
            window.alert(
                "Password reset email sent!, please check your inbox."
            );
        } catch (err) {
            window.alert(err);
        }
    }

    // Sign out
    async signOut(): Promise<void> {
        await this._fbAuth.signOut();
        localStorage.removeItem("user");
        this.router.navigate(["auth/login"]);
    }

    /**
     * =====================================================================
     * User information handlers
     * =====================================================================
     */

    /** @TODO: Remove localStorage management */
    get currentUser(): User {
        return this.user;
        return JSON.parse(localStorage.getItem("user"));
    }

    get privateInfo(): UserPrivate {
        return this.userPrivate;
    }

    // Get the email of the user
    getEmail(): string {
        // console.log("Email: ", this.fbUser.email);
        return this.fbUser?.email || "";
    }

    get userStatus(): String {
        return this.user.userStatus;
    }

    async getAccessToken(): Promise<String> {
        const currentUser = await this._fbAuth.currentUser;
        const accessToken = await currentUser.getIdToken();
        return accessToken;
    }

    /**
     * =====================================================================
     * Database service with firestore
     * =====================================================================
     */

    /** Listen the snapshot the collection user/{uid} */
    onUserDataChange() {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
            `users/${this.fbUser.uid}`
        );

        const user$ = userRef.snapshotChanges().pipe(
            map((snapshot) => {
                const { payload, type } = snapshot;

                if (!payload.exists) {
                    return null;
                }

                const data = payload.data();

                const userData = {} as User;
                userData.uid = data.uid;
                userData.email = data.email;
                userData.displayName = data.display_name;
                userData.emailVerified = data.email_verified;
                userData.photoURL = data.photo_url;
                userData.userStatus = data.user_status;

                this.user = userData;

                return userData;
            })
        );

        return user$;
    }

    async getUserData(): Promise<User> {
        try {
            const userRef: AngularFirestoreDocument<any> = this.afs
                .collection("users")
                .doc(this.user.uid);

            const snapshot = await userRef.get().toPromise();
            const data = snapshot.data();

            const user = {
                uid: data.uid,
                email: data.email,
                displayName: data.display_name,
                emailVerified: data.email_verified,
                photoURL: data.photo_url,
                userStatus: data.user_status,
                availableDates: data.available_dates,
            } as User;

            return user;
        } catch (err) {
            //console.log("Error at getting user data: ", err);
        }
    }

    /* Setting up user data when sign in with username/password, 
    sign up with username/password and sign in with social auth  
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */

    private async _setEmailVerfication(): Promise<void> {
        const user: User = this.currentUser;

        if (!user) return;
        if (!user.emailVerified) return;

        // Set email verified to user
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
            `users/${user.uid}`
        );

        await userRef.set(
            {
                email_verified: user.emailVerified,
            },
            { merge: true }
        );
    }

    private _loadUser(fbUser: firebase.default.User) {
        this.user.displayName = fbUser.displayName;
        this.user.email = fbUser.email;
        this.user.emailVerified = fbUser.emailVerified;
        this.user.photoURL = fbUser.photoURL;
        this.user.uid = fbUser.uid;
    }
}
