import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

export interface FirestoreUser {
    fkey: string;
    created: Date;
    method: string;
    password: string;
    tenant: string;
    uid: string;
    username: string;
}

@Injectable({ providedIn: "root" })
export class UserService {
    user: FirestoreUser;
    constructor(public firestore: AngularFirestore) {}

    async getUserByOwnerPartyId(ownerPartyId: string) {
        const userRef = this.firestore.collection("users").ref;
        const snapshot = await userRef
            .where("businessPartnerID", "==", ownerPartyId)
            .limit(1)
            .get();

        if (snapshot.empty) {
            return null;
        }

        const userSnapshot = snapshot.docs[0];
        const userData = userSnapshot.data() as FirestoreUser;

        return {
            fkey: userSnapshot.id,
            ...userData,
        } as FirestoreUser;
    }

    async getUser(id: string) {
        const userRef = this.firestore.collection("users").doc(id).ref;
        const snapshot = await userRef.get();
        const data = snapshot.data() as FirestoreUser;
        data.fkey = snapshot.id;

        return data;
    }

    async getAllowedAdminUsersUID() {
        const ref = this.firestore.collection("admin_users").ref;

        const snapshots = await ref.get();
        return snapshots.docs.map((s) => s.id);
    }
}
